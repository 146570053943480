@import "./util.scss";

section.card {
  .field {
    position: relative;
    margin: $size_m 0;
    padding: $size_m 0;
    height: 500px;
    background-color: $color_black;
  }
  .card {
    position: absolute;
    top: 100px;
    left: calc(50% - 100px);
    width: 200px;
    height: 350px;
    img { width: 200px; }
    cursor: pointer;

    @keyframes openCardAnimation {
      0% {transform: rotateY(180deg);}
      100% {transform: rotateY(0deg);}
    }
    @keyframes closeCardAnimation {
      0% {transform: rotateY(0deg);}
      100% {transform: rotateY(180deg);}
    }
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    &.front.is-open {
      animation: openCardAnimation 1s;
      animation-fill-mode: forwards;
    }
    &.back.is-open {
      animation: closeCardAnimation 1s ;
      animation-fill-mode: forwards;
    }
  }

  .front, .back {
    // &+input:checked {
    //   animation: openCardAnimation 1s;
    // }
    // cursor: pointer;

  }
  // #hoge:checked+.card {
  //   animation: openCardAnimation 1s infinite;
  // }
}