/** COLOR **/
$color_main: #6e4a55; // #4e608b; /* Billiard Green */
$color_base: #f7f8fc;
$color_accent: #ffbf00; /* Amber */
$color_main_darken: #332227; //#0b1325;
$color_main_lighten: #2b4a8e; /* Teal */

$color_red: #960018; /* Wine red */
$color_yellow: #ffbf00; /* Amber */
$color_green: #008080; /* Teal */
$color_blue: #1e50a2; /* Yale Blue */

$color_white: #FFFFFF;
$color_light: #EFEFEF;
$color_lightgray: #CCCCCC;
$color_gray: #999999;
$color_darkgray: #666666;
$color_dark: #333333;
$color_black: #000000;

/** FONT SIZE **/
$font_lll: 1.4rem;
$font_ll: 1.2rem;
$font_l: 1.1rem;
$font_m: 1rem;
$font_s: .9rem;
$font_ss: .8rem;
$font_sss: .75rem;

/** MARGIN AND PADDING SIZE **/
$size_default: 1rem;
$size_sss: .1rem;
$size_ss: .25rem;
$size_s: .5rem;
$size_m: 1rem;
$size_l: 2rem;
$size_ll: 4rem;

/** MARGIN CLASS **/
.m {
    margin: $size_default;
    &_ss { margin: $size_ss; }
    &_s { margin: $size_s; }
    &_m { margin: $size_m; }
    &_l { margin: $size_l; }
    &_ll { margin: $size_ll; }
}
.mt {
    margin-top: $size_default;
    &_ss { margin-top: $size_ss; }
    &_s { margin-top: $size_s; }
    &_m { margin-top: $size_m; }
    &_l { margin-top: $size_l; }
    &_ll { margin-top: $size_ll; }
}
.ml {
    margin-left: $size_default;
    &_ss { margin-left: $size_ss; }
    &_s { margin-left: $size_s; }
    &_m { margin-left: $size_m; }
    &_l { margin-left: $size_l; }
    &_ll { margin-left: $size_ll; }
}
.mr {
    margin-right: $size_default;
    &_ss { margin-right: $size_ss; }
    &_s { margin-right: $size_s; }
    &_m { margin-right: $size_m; }
    &_l { margin-right: $size_l; }
    &_ll { margin-right: $size_ll; }
}
.mb {
    margin-bottom: $size_default;
    &_ss { margin-bottom: $size_ss; }
    &_s { margin-bottom: $size_s; }
    &_m { margin-bottom: $size_m; }
    &_l { margin-bottom: $size_l; }
    &_ll { margin-bottom: $size_ll; }
}
/** PADDING CLASS **/
.p {
    padding: $size_default;
    &_ss { padding: $size_ss; }
    &_s { padding: $size_s; }
    &_m { padding: $size_m; }
    &_l { padding: $size_l; }
    &_ll { padding: $size_ll; }
}
.pt {
    padding-top: $size_default;
    &_ss { padding-top: $size_ss; }
    &_s { padding-top: $size_s; }
    &_m { padding-top: $size_m; }
    &_l { padding-top: $size_l; }
    &_ll { padding-top: $size_ll; }
}
.pl {
    padding-left: $size_default;
    &_ss { padding-left: $size_ss; }
    &_s { padding-left: $size_s; }
    &_m { padding-left: $size_m; }
    &_l { padding-left: $size_l; }
    &_ll { padding-left: $size_ll; }
}
.pr {
    padding-right: $size_default;
    &_ss { padding-right: $size_ss; }
    &_s { padding-right: $size_s; }
    &_m { padding-right: $size_m; }
    &_l { padding-right: $size_l; }
    &_ll { padding-right: $size_ll; }
}
.pb {
    padding-bottom: $size_default;
    &_ss { padding-bottom: $size_ss; }
    &_s { padding-bottom: $size_s; }
    &_m { padding-bottom: $size_m; }
    &_l { padding-bottom: $size_l; }
    &_ll { padding-bottom: $size_ll; }
}

/**
 * mixins
 * http://bashalog.c-brains.jp/19/07/29-170136.php
 */
@mixin media-sp {
    @media screen and (max-width: 500px) {
      @content;
    }
}
@mixin media-tb {
    @media screen and (max-width: 768px) {
      @content;
    }
}
@mixin media-tb-only {
    @media screen and (min-width: 501px) and (max-width: 768px) {
      @content;
    }
}
@mixin media-pc {
    @media screen and (min-width: 769px) {
        @content;
    }
}

/* General Settings */
$content_width: 900;
$col_300: 300px;
$col_400: 400px;
%font-default { font-family: sans-serif; }
%font-title { font-family: 'Norican', cursive; font-weight: regular; }
%font-heading { font-family: 'Zen Maru Gothic', sans-serif; }
%flex { display: flex; }
.content-wrapper {
	width: $content_width;
	margin: 0 auto;
	@include media-tb {
		width: 100%;
	}
}
.shadow {
	box-shadow: 0 $size_s $size_s hsla(0, 0%, 0%, .1);
}