@import "https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500&display=swap";
html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.m {
  margin: 1rem;
}

.m_ss {
  margin: .25rem;
}

.m_s {
  margin: .5rem;
}

.m_m {
  margin: 1rem;
}

.m_l {
  margin: 2rem;
}

.m_ll {
  margin: 4rem;
}

.mt {
  margin-top: 1rem;
}

.mt_ss {
  margin-top: .25rem;
}

.mt_s {
  margin-top: .5rem;
}

.mt_m {
  margin-top: 1rem;
}

.mt_l {
  margin-top: 2rem;
}

.mt_ll {
  margin-top: 4rem;
}

.ml {
  margin-left: 1rem;
}

.ml_ss {
  margin-left: .25rem;
}

.ml_s {
  margin-left: .5rem;
}

.ml_m {
  margin-left: 1rem;
}

.ml_l {
  margin-left: 2rem;
}

.ml_ll {
  margin-left: 4rem;
}

.mr {
  margin-right: 1rem;
}

.mr_ss {
  margin-right: .25rem;
}

.mr_s {
  margin-right: .5rem;
}

.mr_m {
  margin-right: 1rem;
}

.mr_l {
  margin-right: 2rem;
}

.mr_ll {
  margin-right: 4rem;
}

.mb {
  margin-bottom: 1rem;
}

.mb_ss {
  margin-bottom: .25rem;
}

.mb_s {
  margin-bottom: .5rem;
}

.mb_m {
  margin-bottom: 1rem;
}

.mb_l {
  margin-bottom: 2rem;
}

.mb_ll {
  margin-bottom: 4rem;
}

.p {
  padding: 1rem;
}

.p_ss {
  padding: .25rem;
}

.p_s {
  padding: .5rem;
}

.p_m {
  padding: 1rem;
}

.p_l {
  padding: 2rem;
}

.p_ll {
  padding: 4rem;
}

.pt {
  padding-top: 1rem;
}

.pt_ss {
  padding-top: .25rem;
}

.pt_s {
  padding-top: .5rem;
}

.pt_m {
  padding-top: 1rem;
}

.pt_l {
  padding-top: 2rem;
}

.pt_ll {
  padding-top: 4rem;
}

.pl {
  padding-left: 1rem;
}

.pl_ss {
  padding-left: .25rem;
}

.pl_s {
  padding-left: .5rem;
}

.pl_m {
  padding-left: 1rem;
}

.pl_l {
  padding-left: 2rem;
}

.pl_ll {
  padding-left: 4rem;
}

.pr {
  padding-right: 1rem;
}

.pr_ss {
  padding-right: .25rem;
}

.pr_s {
  padding-right: .5rem;
}

.pr_m {
  padding-right: 1rem;
}

.pr_l {
  padding-right: 2rem;
}

.pr_ll {
  padding-right: 4rem;
}

.pb {
  padding-bottom: 1rem;
}

.pb_ss {
  padding-bottom: .25rem;
}

.pb_s {
  padding-bottom: .5rem;
}

.pb_m {
  padding-bottom: 1rem;
}

.pb_l {
  padding-bottom: 2rem;
}

.pb_ll {
  padding-bottom: 4rem;
}

.content-wrapper {
  width: 900px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    width: 100%;
  }
}

.shadow {
  box-shadow: 0 .5rem .5rem #0000001a;
}

section.hero {
  height: 200px;
  position: relative;
}

section.hero p {
  margin: 0;
}

section.hero img {
  filter: opacity(50%);
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 500px) {
  section.hero {
    height: 100px;
  }
}

section.reading ul {
  margin: 0;
  padding: 1rem 1rem 0 0;
}

section.reading ul li {
  width: 200px;
  height: 200px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 4px;
  line-height: 200px;
  list-style-type: none;
}

section.reading ul li:hover {
  color: #333;
  background-color: #efefef;
}

section.whattoknow ul {
  margin: 0;
  padding: 1rem 0;
}

section.whattoknow ul li {
  border-bottom: 1px dotted #fff;
  margin-bottom: 1rem;
  padding-bottom: .25rem;
  list-style-type: none;
}

.m {
  margin: 1rem;
}

.m_ss {
  margin: .25rem;
}

.m_s {
  margin: .5rem;
}

.m_m {
  margin: 1rem;
}

.m_l {
  margin: 2rem;
}

.m_ll {
  margin: 4rem;
}

.mt {
  margin-top: 1rem;
}

.mt_ss {
  margin-top: .25rem;
}

.mt_s {
  margin-top: .5rem;
}

.mt_m {
  margin-top: 1rem;
}

.mt_l {
  margin-top: 2rem;
}

.mt_ll {
  margin-top: 4rem;
}

.ml {
  margin-left: 1rem;
}

.ml_ss {
  margin-left: .25rem;
}

.ml_s {
  margin-left: .5rem;
}

.ml_m {
  margin-left: 1rem;
}

.ml_l {
  margin-left: 2rem;
}

.ml_ll {
  margin-left: 4rem;
}

.mr {
  margin-right: 1rem;
}

.mr_ss {
  margin-right: .25rem;
}

.mr_s {
  margin-right: .5rem;
}

.mr_m {
  margin-right: 1rem;
}

.mr_l {
  margin-right: 2rem;
}

.mr_ll {
  margin-right: 4rem;
}

.mb {
  margin-bottom: 1rem;
}

.mb_ss {
  margin-bottom: .25rem;
}

.mb_s {
  margin-bottom: .5rem;
}

.mb_m {
  margin-bottom: 1rem;
}

.mb_l {
  margin-bottom: 2rem;
}

.mb_ll {
  margin-bottom: 4rem;
}

.p {
  padding: 1rem;
}

.p_ss {
  padding: .25rem;
}

.p_s {
  padding: .5rem;
}

.p_m {
  padding: 1rem;
}

.p_l {
  padding: 2rem;
}

.p_ll {
  padding: 4rem;
}

.pt {
  padding-top: 1rem;
}

.pt_ss {
  padding-top: .25rem;
}

.pt_s {
  padding-top: .5rem;
}

.pt_m {
  padding-top: 1rem;
}

.pt_l {
  padding-top: 2rem;
}

.pt_ll {
  padding-top: 4rem;
}

.pl {
  padding-left: 1rem;
}

.pl_ss {
  padding-left: .25rem;
}

.pl_s {
  padding-left: .5rem;
}

.pl_m {
  padding-left: 1rem;
}

.pl_l {
  padding-left: 2rem;
}

.pl_ll {
  padding-left: 4rem;
}

.pr {
  padding-right: 1rem;
}

.pr_ss {
  padding-right: .25rem;
}

.pr_s {
  padding-right: .5rem;
}

.pr_m {
  padding-right: 1rem;
}

.pr_l {
  padding-right: 2rem;
}

.pr_ll {
  padding-right: 4rem;
}

.pb {
  padding-bottom: 1rem;
}

.pb_ss {
  padding-bottom: .25rem;
}

.pb_s {
  padding-bottom: .5rem;
}

.pb_m {
  padding-bottom: 1rem;
}

.pb_l {
  padding-bottom: 2rem;
}

.pb_ll {
  padding-bottom: 4rem;
}

.content-wrapper {
  width: 900px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    width: 100%;
  }
}

.shadow {
  box-shadow: 0 .5rem .5rem #0000001a;
}

section.card .field {
  height: 500px;
  background-color: #000;
  margin: 1rem 0;
  padding: 1rem 0;
  position: relative;
}

section.card .card {
  width: 200px;
  height: 350px;
  cursor: pointer;
  backface-visibility: hidden;
  position: absolute;
  top: 100px;
  left: calc(50% - 100px);
}

section.card .card img {
  width: 200px;
}

@keyframes openCardAnimation {
  0% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0);
  }
}

@keyframes closeCardAnimation {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(180deg);
  }
}

section.card .card.front.is-open {
  animation: 1s forwards openCardAnimation;
}

section.card .card.back.is-open {
  animation: 1s forwards closeCardAnimation;
}

body {
  font-family: Zen Maru Gothic, sans-serif;
}

a {
  color: #fff;
  text-decoration: none;
}

header {
  color: #efefef;
  background-color: #332227;
  justify-content: space-between;
  padding: 1rem;
  display: flex;
}

header h1.header {
  margin: 0;
  font-size: 1.2rem;
}

header h1.header span {
  padding: 0 .5rem;
  font-size: .9rem;
}

header div.setting label {
  cursor: pointer;
  padding: 1rem 0;
}

header div.setting ul, header div.setting input {
  display: none;
}

header div.setting input:checked + ul {
  z-index: 2;
  background-color: #fff;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 60px;
  right: 6px;
}

header div.setting input:checked + ul li {
  color: #333;
  text-align: center;
  padding: .5rem 2rem;
  list-style-type: none;
}

header div.setting input:checked + ul li:hover {
  cursor: pointer;
  background-color: #ffbf00;
}

main {
  min-height: 750px;
  color: #efefef;
  background-color: #6e4a55;
  padding: 1rem;
}

@media screen and (max-width: 500px) {
  main {
    padding: 0;
  }
}

main section {
  box-sizing: border-box;
  width: 900px;
  background-color: #332227;
  margin: 0 auto;
  padding: 2rem;
}

@media screen and (max-width: 500px) {
  main section {
    width: 100%;
    padding: 2rem .5rem;
  }
}

main section h2 {
  margin: 0;
}

footer {
  color: #efefef;
  background-color: #332227;
  padding: 1rem;
}

@media screen and (max-width: 500px) {
  footer {
    padding: 1rem .5rem;
  }
}

/*# sourceMappingURL=index.7faee618.css.map */
