section.hero {
  position: relative;
  height: 200px;
  p {
    margin: 0;
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    filter: opacity(50%);
  }
  @include media-sp {
    height: 100px;
  }
}
section.reading {
  ul {
    margin: 0;
    padding: $size_m $size_m 0 0;
    li {
      width: 200px;
      height: 200px;
      list-style-type: none;
      border: 1px solid $color_white;
      border-radius: 4px;
      text-align: center;
      line-height: 200px;
      cursor: pointer;
      &:hover {
        background-color: $color_light;
        color: $color_dark;
      }
      @mixin media-sp {
        width: 100%;
      }
    }
  }
}
section.whattoknow {
  ul {
    margin: 0;
    padding: $size_m 0;
    li {
      margin-bottom: $size_m;
      padding-bottom: $size_ss;
      list-style-type: none;
      border-bottom: 1px dotted $color_white;
    }
  }
}