@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500&display=swap');
@import "/node_modules/normalize.css/normalize.css";
@import "./util.scss";
@import "./index.scss";
@import "./one.scss";

body {
  font-family: 'Zen Maru Gothic', sans-serif;
}
a {
  color: $color_white;
  text-decoration: none;
}
header {
  display: flex;
  justify-content: space-between;
  padding: $size_m;
  // background: linear-gradient(to bottom right, $color_main_darken, $color_main);
  background-color: $color_main_darken;
  color: $color_light;
  h1.header {
    margin: 0;
    font-size: $font_ll;
    span {
      padding: 0 $size_s;
      font-size: $font_s;
    }
  }
  div.setting {
    label {
      padding: $size_m 0;
      cursor: pointer;
    }
    ul {
      display: none;
    }
    input {
      display: none;
      &:checked + ul {
        display: block;
        z-index: 2;
        position: absolute;
        top: 60px;
        right: 6px;
        margin: 0;
        padding: 0;
        background-color: $color_white;
        li {
          color: $color_dark;
          list-style-type: none;
          padding: $size_s $size_l;
          text-align: center;
          &:hover {
            background-color: $color_accent;
            cursor: pointer;
          }
        }
      }
    }
  }
}
main {
  padding: $size_m;
  min-height: 750px;
  background-color: $color_main;
  color: $color_light;
  @include media-sp {
    padding: 0;
  }
  section {
    box-sizing: border-box;
    background-color: $color_main_darken;
    margin: 0 auto;
    padding: $size_l;
    width: $content_width;
    @include media-sp {
      width: 100%;
      padding: $size_l $size_s;
    }
    h2 {
      margin: 0;
    }
  }
}

footer {
  padding: $size_m;
  background-color: $color_main_darken;
  color: $color_light;
  @include media-sp {
    padding: $size_m $size_s;
  }
}